import React from 'react';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

interface Props {
  items: PhotoSwipeUIDefault.Item[];
  index?: number;
  onClose?: () => void;
}

export default class PhotoSwipeComponent extends React.PureComponent<Props> {
  private pswpElementRef: React.RefObject<HTMLDivElement>;

  private pswpGallery: null | PhotoSwipe<PhotoSwipeUIDefault.Options>;

  public constructor(props: Props) {
    super(props);

    this.pswpElementRef = React.createRef();
    this.pswpGallery = null;
  }

  public componentDidMount() {
    const pswpElement = this.pswpElementRef.current;
    const { items, index, onClose } = this.props;

    if (pswpElement) {
      this.pswpGallery = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUIDefault,
        items,
        {
          index,
          shareButtons: [
            {
              id: 'facebook',
              label: 'Bei Facebook teilen',
              url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}',
            },
            {
              id: 'twitter',
              label: 'Bei Twitter teilen',
              url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}',
            },
            {
              id: 'whatsapp',
              label: 'Bei WhatsApp teilen',
              url: 'whatsapp://send?text={{text}}',
            },
          ],
        },
      );
      if (onClose) {
        this.pswpGallery.listen('close', onClose);
      }
      this.pswpGallery.init();
    }
  }

  public render() {
    return (
      <div
        ref={this.pswpElementRef}
        className="pswp"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="pswp__bg" />
        <div className="pswp__scroll-wrap">
          <div className="pswp__container">
            <div className="pswp__item" />
            <div className="pswp__item" />
            <div className="pswp__item" />
          </div>
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter" />
              <button
                type="button"
                className="pswp__button pswp__button--close"
                title="Schließen (Esc)"
              />
              <button
                type="button"
                className="pswp__button pswp__button--share"
                title="Teilen"
              />
              <button
                type="button"
                className="pswp__button pswp__button--fs"
                title="Vollbildmodus an/aus"
              />
              <button
                type="button"
                className="pswp__button pswp__button--zoom"
                title="Zoom +/-"
              />
              <div className="pswp__preloader">
                <div className="pswp__preloader__icn">
                  <div className="pswp__preloader__cut">
                    <div className="pswp__preloader__donut" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip" />
            </div>
            <button
              type="button"
              className="pswp__button pswp__button--arrow--left"
              title="Voriges (Pfeil links)"
            />
            <button
              type="button"
              className="pswp__button pswp__button--arrow--right"
              title="Nächstes (Pfeil rechts)"
            />
            <div className="pswp__caption">
              <div className="pswp__caption__center" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
