import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, PageRendererProps, Link } from 'gatsby';
import Markdown from 'react-markdown';
import { v4 } from 'uuid';

import Layout from '../components/Layout';
import PhotoSwipe from '../components/PhotoSwipe';
import Content, { HTMLContent } from '../components/Content';
import img404 from '../img/img404.svg';

interface GalleryImage {
  childImageSharp: {
    thumbnail: { src: string; width: number; height: number };
    original: { base64: string; src: string; width: number; height: number };
  };
}

interface GallerySlide {
  image: GalleryImage;
  alt?: string;
  caption?: string;
  copyright?: string;
}

interface PageProps extends PageRendererProps {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        type: string;
        title: string;
        description: string;
        indexImage: {
          alt?: string;
          image: null | {
            childImageSharp: {
              original: {
                src: string;
                width: number;
                height: number;
              };
            };
          };
        };
        slides: GallerySlide[];
      };
    };
  };
}

interface TemplateProps {
  title: string;
  location?: string;
  schema?: object;
  content: string;
  contentComponent?: typeof Content | typeof HTMLContent;
  slides: GallerySlide[];
}

interface TemplateState {
  index: number;
  isOpen: boolean;
}

export class GalleryPageTemplate extends React.Component<
  TemplateProps,
  TemplateState
> {
  public constructor(props: TemplateProps) {
    super(props);

    this.state = {
      index: 0,
      isOpen: false,
    };
  }

  public render() {
    const {
      title,
      location,
      schema,
      content,
      contentComponent,
      slides,
    } = this.props;
    const { isOpen, index } = this.state;
    const PageContent = contentComponent || Content;

    return (
      <Layout title={title} location={location} schema={schema}>
        <section className="section">
          <div className="container">
            <div className="breadcrumb" aria-label="Brotkrümelnavigation">
              <ul>
                <li>
                  <Link to="/galerien">Galerien</Link>
                </li>
                <li className="is-active">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" aria-current="page">
                    {title}
                  </a>
                </li>
              </ul>
            </div>
            <h1 className="title">{title}</h1>
            <PageContent className="content" content={content} />
            {slides && slides.length && (
              <div className="columns is-multiline">
                {slides.map((slide, slideIndex) => (
                  <div
                    key={v4()}
                    className="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen"
                  >
                    <div className="card is-fullheight">
                      <div
                        className="card-image"
                        role="button"
                        tabIndex={0}
                        onClick={event => {
                          event.preventDefault();
                          this.setState({ isOpen: true, index: slideIndex });
                        }}
                        onKeyPress={event => {
                          if (event.keyCode === 13 || event.keyCode === 32) {
                            event.preventDefault();
                            this.setState({ isOpen: true, index: slideIndex });
                          }
                        }}
                      >
                        <figure className="image is-4by3">
                          {slide.image === null ? (
                            <img src={img404} alt="Nicht gefunden" />
                          ) : (
                            <img
                              src={
                                typeof slide.image === 'object'
                                  ? slide.image.childImageSharp.thumbnail.src
                                  : slide.image
                              }
                              alt={slide.alt}
                              width={
                                typeof slide.image === 'object'
                                  ? slide.image.childImageSharp.thumbnail.width
                                  : 512
                              }
                              height={
                                typeof slide.image === 'object'
                                  ? slide.image.childImageSharp.thumbnail.height
                                  : 384
                              }
                            />
                          )}
                        </figure>
                      </div>
                      {(slide.caption || slide.copyright) && (
                        <div className="card-content">
                          {slide.caption && (
                            <Markdown
                              className="content"
                              source={slide.caption}
                            />
                          )}
                          {slide.copyright && (
                            <Markdown
                              className="content"
                              source={slide.copyright}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
        {isOpen && slides && (
          <PhotoSwipe
            items={slides.map(slide => {
              const slideTitle = `${slide.caption || ''}${
                slide.caption && slide.copyright ? '\n' : ''
              }${slide.copyright || ''}`;
              if (typeof slide.image === 'object') {
                return {
                  src: slide.image.childImageSharp.original.src,
                  msrc: slide.image.childImageSharp.original.base64,
                  w: slide.image.childImageSharp.original.width,
                  h: slide.image.childImageSharp.original.height,
                  title: slideTitle,
                };
              }
              return {
                src: slide.image,
                w: 1024,
                h: 768,
                title: slideTitle,
              };
            })}
            index={index}
            onClose={() => {
              this.setState({
                isOpen: false,
              });
            }}
          />
        )}
      </Layout>
    );
  }
}

export default ({ data, location }: PageProps) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
      </Helmet>
      <GalleryPageTemplate
        title={data.markdownRemark.frontmatter.title}
        location={location.href}
        schema={{
          '@type': frontmatter.type,
          name: frontmatter.title,
          description: frontmatter.description,
          url: location.href,
          associatedMedia: [
            ...(frontmatter.slides && frontmatter.slides.length > 0
              ? frontmatter.slides.map(
                  slide =>
                    slide.image && {
                      '@type': 'ImageObject',
                      contentUrl: slide.image.childImageSharp.original.src,
                      width: slide.image.childImageSharp.original.width,
                      height: slide.image.childImageSharp.original.height,
                      thumbnail: {
                        '@type': 'ImageObject',
                        contentUrl: slide.image.childImageSharp.thumbnail.src,
                        width: slide.image.childImageSharp.thumbnail.width,
                        height: slide.image.childImageSharp.thumbnail.height,
                      },
                      ...(slide.caption && { caption: slide.caption }),
                      ...(slide.copyright && {
                        copyrightHolder: slide.copyright,
                      }),
                    },
                )
              : []),
          ],
        }}
        content={data.markdownRemark.html}
        contentComponent={HTMLContent}
        slides={frontmatter.slides}
      />
    </>
  );
};

export const galleryPageQuery = graphql`
  query GalleryPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        type
        title
        description
        slides {
          image {
            childImageSharp {
              thumbnail: resize(width: 512, height: 384, cropFocus: ENTROPY) {
                src
                width
                height
              }
              original: fluid(maxWidth: 2048) {
                base64
                src
                width: presentationWidth
                height: presentationHeight
              }
            }
          }
          alt
          caption
          copyright
        }
      }
    }
  }
`;
